import { showBanner } from "./banner";
import { showBody } from "./body";
import { showChat } from "./chat";
import { showLanding } from "./landing";
import { showPopup } from "./popup";
import { showTrigger } from "./trigger";

export const actions = {
    trigger: showTrigger(),
    banner: showBanner(),
    popup: showPopup(),
    chat: showChat(),
    landing: showLanding(),
    body: showBody("#quiz-player"),
  };