import { loader } from "./loader";
import { playerUrl } from "./init";

let iframe = null;
let isLoading = true;
let loading;

const onMessageIframe = (data) => {

  const {
    data: { type, height },
  } = data;
  if (!(iframe && height)) return;

  loading.remove();
  isLoading = false;

  if (iframe.clientWidth < 800) {
    iframe.style.height = `${iframe.clientWidth * 16 / 9}px`;
  } else {
    iframe.style.height = `${height}px`;
  }
};

export const showBody = (selector) => {
  iframe = document.createElement("iframe");
  const width = 850;

  isLoading = true;
  loading = loader();

  iframe.style.border = "none";
  iframe.style.width = "100%";
  //iframe.style.height = "calc(100vw * 0.5625)";

  return {
    isInit: false,
    selector: selector,
    init: function (body, quizConfig, isPreview, quizId) {
      iframe.src = playerUrl;
      const player = body.querySelector(this.selector) ?? body.querySelector(`#quiz-player-${quizId}`);
      player.style.display = "flex";
      player.append(loading);
      player.append(iframe);
      this.isInit = true;
      window.addEventListener("message", onMessageIframe, false);
    },
    update: (body, quizConfig) => { },
    remove: function () {
      iframe.remove();
      this.isInit = false;
      window.removeEventListener("message", onMessageIframe, false);
    },
  };
};
