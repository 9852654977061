import { actions } from "./actions";
import { getConfig } from "./init";

const initQuiz = async function () {
  // var arrScripts = document.getElementsByTagName('script');
  // console.log(arrScripts[arrScripts.length - 1]);
  // console.log(document.currentScript);
  const apiBaseUrl = process.env.REACT_APP_BASE_URL;

  const body = document.querySelector("body");
  const [script] = document.querySelectorAll(
    'script[data-quiz-id]:not([data-start="true"])'
  );
  script.dataset.start = true;
  const quizId = script.dataset.quizId;
  const type = script.dataset.type;

  if (quizId) {
    try {
      const quizConfig = await getConfig(quizId, false);
      const item = actions[type];
      item.init(body, quizConfig, false, quizId);
    } catch (error) {
      console.error(error);
    }
  }
};
initQuiz();
