import { getPopup } from "./common";


export const showPopup = () => {
  let backdrop = null;
  let timeout = null;

  return {
    isInit: false,
    init: function (body, quizConfig, isPreview) {
      backdrop = getPopup();
      backdrop.style.height = "0";
      body.append(backdrop);
      timeout = setTimeout(() => {
        backdrop.style.height = "100%";      
      },  isPreview ? 0 : (quizConfig.popup_opening_time ?? 0) * 1000);
      this.isInit = true;
    },
    update: function (body, quizConfig) {},
    remove: function () {
      backdrop.remove();
      if (timeout) clearTimeout(timeout);
      timeout = null;
      this.isInit = false;
    },
  };
};
