import { playerBaseUrl } from "./init";

export const closeIcon = `${playerBaseUrl}/images/close.svg`;
export const userIcon = `${playerBaseUrl}/images/user.svg`;

export const bannerIcons = {
  1: `${playerBaseUrl}/images/gift.svg`,
  2: `${playerBaseUrl}/images/star.svg`,
};


export const triggerIcons = {
    1: `${playerBaseUrl}/images/gift.svg`,
    2: `${playerBaseUrl}/images/star.svg`,
  };
