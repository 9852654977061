import { clearStyles, getBackdrop, setStyles } from "./common";
import { loader } from "./loader";
import { playerUrl, closeIconUrl, btnIcon } from "./init";

export const showChat = () => {
  const backdrop = document.createElement("div"); //getBackdrop();
  const div = document.createElement("div");
  const iframe = document.createElement("iframe");
  const close = document.createElement("button");
  let icon = "";


 const positionBtn = (quizConfig) => 
 {switch (quizConfig.chat_position) {
  case 1:
    btn.style.left = "16px";
    btn.style.right = null;
    break;
  case 2:
    btn.style.right = "16px";
    btn.style.left = null;
    break;
}};

  const btn = document.createElement("button");
  btn.style.position = "fixed";
  btn.style.right = "0";
  btn.style.bottom = "16px";
  btn.style.display = "flex";
  btn.style.alignItems = "center";
  btn.style.zIndex = "99999999999";
  btn.style.padding = "12px 32px";
  btn.style.border = "none";
  // btn.style.height = "24px";
  btn.style.borderRadius = "20px";
  btn.style.fontSize = "13px"; //10
  btn.style.fontWeight = "500";
  btn.style.lineHeight = "1.23"; //1.6
  btn.style.cursor = "pointer";
  btn.onclick = () => {
    //backdrop.append(div);
    div.style.display = "block"
  }

  let isLoading = true;
  const loading = loader();

  const chatHeight = (quizConfig) => {
    if (window.innerWidth < 800) {
        iframe.style.height = "100%";
        div.style.width = "100%";
        div.style.height = window.innerHeight + "px"; //"100vh";
        div.style.bottom = "0px";
        div.style.right = "0px";
        div.style.left = "0px";
        div.style.padding = "0px";
        div.style.borderRadius = "0px";
        iframe.style.width = "100%";
        iframe.style.borderRadius = "0px";
        close.style.right = "15px";
        close.style.top = "15px";
        positionBtn(quizConfig);
    } else {
      iframe.style.height = "670px"; //`${iframeBody.clientHeight}px`;
      const width = 340;
      div.style.width = null;
      div.style.height = null;
      iframe.style.border = "none";
      iframe.style.width = width + "px";
      iframe.style.display = "block";
      div.style.bottom = "16px";
      div.style.borderRadius = "6px";
      close.style.top = "0px";
      positionBtn(quizConfig);

      switch (quizConfig.chat_position) {
        case 1:
          div.style.left = "16px";
          div.style.right = null;
          div.style.padding = "24px 24px 0px 0px",
          close.style.right = "0px";
          close.style.left = null;
          break;
        case 2:
          div.style.right = "16px";
          div.style.left = null;
          div.style.padding = "24px 0px 0px 24px",
          close.style.right = null;
          close.style.left = "0px";
          break;
      }
    }
  }

  const innerFunc = (body, quizConfig) => {
    const divStyles = {
      position: "fixed",
      bottom: "16px",
      zIndex: "999999999999",
      // padding: "24px 24px 0px 0px",
      // display: "none",
      overflow: "hidden",
    };

    btn.style.color = quizConfig.chat_text_color;
    btn.style.backgroundColor = quizConfig.chat_color;

    btn.innerHTML = `
    <span style="display: block; width: 13px; height: 13px;">${icon}</span> 
    ${quizConfig.chat_button_text ? `<span style="margin-left: 10px;">${quizConfig.chat_button_text?? ""}</span>` : ""}
    `;
    // btn.innerText = quizConfig.chat_button_text?? "";

    body.onresize = () => {
      chatHeight(quizConfig);
    }

    clearStyles(div);
    setStyles(div, divStyles);
    chatHeight(quizConfig);
  };

  let timeout = null;
  const result = {
    isInit: false,
    init: async function (body, quizConfig, isPreview) {
      const res = await fetch(btnIcon);
      icon = await res.text();

      innerFunc(body, quizConfig);

      const width = 340;

      iframe.style.border = "none";
      iframe.style.width = width + "px";
      iframe.style.borderRadius = "6px";
      iframe.style.display = "block";
      iframe.src = playerUrl;

      iframe.onload = () => {
        setTimeout(() => {
          loading.remove();
          isLoading = false;
          chatHeight(quizConfig);
          if (window.innerWidth < 800) {
            close.style.opacity = "0.4";
          } else {
            close.style.opacity = "1";
          }

        }, 0);
        //setTimeout(() => loading.remove());
      };


      const closeStyles = {
        border: "none",
        position: "absolute",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        backgroundColor: "#fff",
        textAlign: "center",
        padding: "5px",
        cursor: "pointer",
        opacity: "0",
        transition: "opacity 1000ms linear",
      };

      clearStyles(close);
      setStyles(close, closeStyles);

      close.innerHTML = `<img style="display: block" src="${closeIconUrl}" title="close btn" />`;

      close.onclick = () => {
        //div.remove();
        div.style.display = "none"
      };

      div.append(loading);
      div.append(close);
      div.append(iframe);
      backdrop.append(btn);
      backdrop.append(div);
      body.append(backdrop);

      div.style.display = "none";
      timeout = setTimeout(
        () => div.style.display = "block",
        isPreview ? 0 : quizConfig.chat_opening_time * 1000
      );

      this.isInit = true;
    },
    update: innerFunc,
    remove: function () {
      backdrop.remove();
      if (timeout) clearTimeout(timeout);
      timeout = null;
      this.isInit = false;
    },
  };
  return result;
};
